import Typography from "@mui/material/Typography"
import CourseSection from "../components/CourseSection"
import List from "../components/List"
import PageBox from "../components/PageBox"
import SectionBox from "../components/SectionBox"
import { badiStaefa, badiUerikon, gsteigtobel, wieseKirche } from "../data/locations"
import { monika } from "../data/persons"
import { ICourse } from "../interfaces/ICourse"

export default function MeditationInMotion() {
  const courses: ICourse[] = [
    {
      title: "Shibashi im Freien",
      description: ["Bei Regenwetter, maximum 4 Teilnehmer am Gsteigtobel 1, Uerikon"],
      type: "Lektion",
      location: wieseKirche,
      person: [monika],
      price: { amount: 25, paymentType: "cash", info: "pro Lektion (60min)" },
      registrationUntil: { info: "1 Tag vorher (Einstieg jederzeit möglich)" },
      occurances: [
        { start: new Date(2024, 5, 5, 9), end: new Date(2024, 5, 5, 10) },
        { start: new Date(2024, 5, 12, 9), end: new Date(2024, 5, 12, 10) },
        { start: new Date(2024, 5, 26, 9), end: new Date(2024, 5, 26, 10) },
        { start: new Date(2024, 6, 3, 9), end: new Date(2024, 6, 3, 10) },
        { start: new Date(2024, 6, 24, 9), end: new Date(2024, 6, 24, 10) },
        { start: new Date(2024, 7, 7, 9), end: new Date(2024, 7, 7, 10) },
        { start: new Date(2024, 7, 14, 9), end: new Date(2024, 7, 14, 10) },
        { start: new Date(2024, 7, 21, 9), end: new Date(2024, 7, 21, 10) },
        { start: new Date(2024, 8, 11, 9), end: new Date(2024, 8, 11, 10) },
        { start: new Date(2024, 8, 18, 9), end: new Date(2024, 8, 18, 10) },
        { start: new Date(2024, 9, 9, 10), end: new Date(2024, 9, 9, 11) },
        { start: new Date(2024, 9, 23, 10), end: new Date(2024, 9, 23, 11) },
        { start: new Date(2024, 9, 30, 10), end: new Date(2024, 9, 30, 11) },
      ],
    },
    {
      title: "Shibashi am Gsteigtobel",
      description: ["Meditationsraum privat, Kleingruppe"],
      type: "Lektion",
      location: gsteigtobel,
      person: [monika],
      price: { amount: 25, paymentType: "cash", info: "pro Lektion (60min)" },
      registrationUntil: { info: "1 Tag vorher (Einstieg jederzeit möglich)" },
      occurances: [
        { start: new Date(2023, 10, 15, 9), end: new Date(2023, 10, 15, 10) },
        { start: new Date(2023, 10, 22, 9), end: new Date(2023, 10, 22, 10), info: "Fällt aus." },
        { start: new Date(2023, 10, 29, 9), end: new Date(2023, 10, 29, 10) },
        { start: new Date(2023, 11, 13, 9), end: new Date(2023, 11, 13, 10) },
        { start: new Date(2023, 11, 20, 9), end: new Date(2023, 11, 20, 10) },
        { start: new Date(2024, 0, 17, 9), end: new Date(2024, 0, 17, 10) },
        { start: new Date(2024, 0, 24, 9), end: new Date(2024, 0, 24, 10) },
        { start: new Date(2024, 0, 31, 9), end: new Date(2024, 0, 31, 10) },
        { start: new Date(2024, 1, 7, 9), end: new Date(2024, 1, 7, 10) },
        { start: new Date(2024, 1, 14, 9), end: new Date(2024, 1, 14, 10) },
        { start: new Date(2024, 2, 6, 9), end: new Date(2024, 2, 6, 10) },
        { start: new Date(2024, 2, 13, 9), end: new Date(2024, 2, 13, 10) },
        { start: new Date(2024, 2, 20, 9), end: new Date(2024, 2, 20, 10) },
        { start: new Date(2024, 2, 27, 9), end: new Date(2024, 2, 27, 10) },
        { start: new Date(2024, 9, 16, 9), end: new Date(2024, 9, 16, 10) },
        { start: new Date(2024, 10, 6, 9), end: new Date(2024, 10, 6, 10) },
        { start: new Date(2024, 10, 13, 9), end: new Date(2024, 10, 13, 10) },
        { start: new Date(2024, 10, 20, 9), end: new Date(2024, 10, 20, 10) },
        { start: new Date(2024, 10, 27, 9), end: new Date(2024, 10, 27, 10) },
      ],
    },
    {
      title: "Shibashi bei activecity Stäfa",
      description: ["Gratis Sport für Alle! 27. Mai - 6. Juli 2024"],
      externalLink: "https://www.activecity.ch/de/staefa/",
      externalLinkText: "ActiveCity",
      type: "Lektion",
      location: badiStaefa,
      person: [monika],
      registrationUntil: { info: "Ohne Anmeldung für alle" },
      price: { paymentType: "voluntary", info: "Gratis Sport für Alle!" },
      occurances: [
        { start: new Date(2024, 4, 30, 19), end: new Date(2024, 4, 30, 20) },
        { start: new Date(2024, 5, 13, 19), end: new Date(2024, 5, 13, 20) },
        { start: new Date(2024, 5, 27, 19), end: new Date(2024, 5, 27, 20) },
      ],
    },
    {
      title: "Shibashi am See",
      description: ["Bei Regenwetter, maximum 4 Teilnehmer am Gsteigtobel 1, Uerikon"],
      type: "Lektion",
      location: badiUerikon,
      person: [monika],
      price: { amount: 23, paymentType: "cash", info: "pro Lektion (60min), Eintritt Badi 2 CHF ab 18 Uhr" },
      registrationUntil: { info: "1 Tag vorher (Einstieg jederzeit möglich)" },
      occurances: [
        { start: new Date(2024, 5, 11, 18, 30), end: new Date(2024, 5, 11, 19, 30) },
        { start: new Date(2024, 5, 18, 18, 30), end: new Date(2024, 5, 18, 19, 30) },
        { start: new Date(2024, 5, 25, 18, 30), end: new Date(2024, 5, 25, 19, 30) },
        { start: new Date(2024, 6, 2, 18, 30), end: new Date(2024, 6, 2, 19, 30) },
        { start: new Date(2024, 6, 23, 18, 30), end: new Date(2024, 6, 23, 19, 30) },
        { start: new Date(2024, 7, 6, 18, 30), end: new Date(2024, 7, 6, 19, 30) },
        { start: new Date(2024, 7, 13, 18, 30), end: new Date(2024, 7, 13, 19, 30) },
        { start: new Date(2024, 7, 20, 18, 30), end: new Date(2024, 7, 20, 19, 30) },
        { start: new Date(2024, 7, 27, 18, 30), end: new Date(2024, 7, 27, 19, 30) },
      ],
    },
    // {
    //   title: "Shibashi online",
    //   description: [],
    //   type: "Lektion",
    //   location: zoom,
    //   person: [monika],
    //   price: { amount: 20, paymentType: "prepayment", info: "pro Lektion (60min)" },
    //   registrationUntil: { info: "1 Tag vorher (Einstieg jederzeit möglich)" },
    //   occurances: [
    //     { start: new Date(2023, 5, 5, 9), end: new Date(2023, 5, 5, 10) },
    //   ],
    // },
  ]

  return (
    <PageBox title="Meditation in Bewegung" image="/images/VollDaGanzWeg.png">
      <SectionBox>
        <Typography variant="h2">Shibashi: Harmonie von Körper, Geist und Energie/Qi</Typography>
        <Typography variant="body1">
          Diese sanfte Bewegungspraxis fördert die Harmonie von Körper, Geist und Qi-Fluss im Körper und unterstützt ein
          gesundes Gleichgewicht und Wohlbefinden. Shibashi stärkt deinen Körper, beruhigt und weckt deinen Geist und
          harmonisiert deine innere Energie, auch als Qi bekannt.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Was ist Shibashi?</Typography>
        <Typography variant="body1">
          Shibashi ist Da-Sein, ist Meditation in Bewegung. Bekannt als "18 Gesundheitsübungen des Tai Chi Qigong". Eine
          Form des Qigong, die in den 1970er Jahren von Meister Lin Housheng entwickelt wurde.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Die Wirkung von Shibashi</Typography>

        <List
          items={[
            {
              title: "Verbesserte körperliche Gesundheit",
              text: "Die sanften und fließenden Bewegungen dehnen, stärken und entspannen deinen Körper und der Energiefluss im Körper wird angeregt. ",
            },
            {
              title: "Stressabbau und Entspannung",
              text: "Durch die Kombination von langsamen Bewegungen, achtsamer Atmung und Konzentration erreichst du Gelassenheit, Ruhe und inneren Frieden.",
            },
            {
              title: "Energieharmonisierung und Vitalitätssteigerung",
              text: "Shibashi zielt darauf ab, den Energiefluss im Körper zu harmonisieren und Blockaden im Qi zu lösen. Durch regelmäßiges Praktizieren wird die Vitalität gestärkt, ein gesundes Gleichgewicht  hergestellt und die emotionale Stabilität verbessert.",
            },
            {
              title: "Verbesserte Konzentration und geistige Klarheit",
              text: "Indem du deine Aufmerksamkeit auf den gegenwärtigen Moment richtest und dich auf die Ausführung der Bewegungen und die Empfindungen im Körper konzentrierst, verbessert sich die geistige Klarheit und die kognitive Leistungsfähigkeit.",
            },
          ]}
        ></List>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Entdecke die transformative Kraft von Shibashi</Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Barbara Lehner und Antoinette Brem, beide freischaffende Theologinnen, lernten bei Marimil Lobregat
          (1928-2017) aus den Philippinen die 18 Gesundheitsformen aus dem Qi Gong. Mit diesen Formen üben wir die
          wohltuende Langsamkeit und richten unser Augenmerk auf uns selbst.
        </Typography>

        <Typography variant="body1">
          Informiere dich noch heute über meine aktuellen Kursangebote. Ich freue mich darauf, dich auf deiner Reise zu
          einem gesünderen und ausgeglicheneren Lebensstil zu begleiten!
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h3">Gruppenkurse und Workshops</Typography>

        <Typography variant="body1">
          In meinen Kursen kannst du Shibashi einfach unter Anleitung erlernen und praktizieren. Die Gruppendynamik und
          das gemeinsame Üben schaffen eine unterstützende und motivierende Umgebung, um deine Fähigkeiten zu entwickeln
          und von den positiven Effekten des Shibashi zu profitieren.
        </Typography>
      </SectionBox>

      <CourseSection courses={courses}></CourseSection>
    </PageBox>
  )
}
